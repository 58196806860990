<template>
  <div class="member-shop-order-products-table">
    <div class="flex items-center justify-between font-medium text-md" style="margin-bottom: 16px">
      <p class="text-primary-100">訂購商品資訊</p>
    </div>
    <el-table :data="displayData">
      <el-table-column label="商品圖片" align="center" width="150px">
        <template slot-scope="scope">
          <img :src="displayImg(scope.row)">
        </template>
      </el-table-column>
      <el-table-column label="名稱" prop="name" align="center" />
      <el-table-column label="是否為贈品" prop="isGift" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.isGift.tagType">{{ scope.row.isGift.label }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="價格" prop="price" align="center" />
      <el-table-column label="商品數量" prop="quantity" align="center" />
      <el-table-column label="小計" prop="total" align="center" />
      <el-table-column label="發送進度" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.status.tagType">{{ scope.row.status.label }}</Tag>
        </template>
      </el-table-column>
      <el-table-column v-if="checkCode('ACT_PRODUCT_SENT') || checkCode('ACT_PRODUCT_RECYCLED')" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkCode('ACT_PRODUCT_SENT') && get(scope.row, 'status.value') === 'pending'"
            v-loading="loading"
            class="text-primary-100 underline"
            type="text"
            @click="manualSent(scope.row)"
          >
            手動發送
          </el-button>
          <el-button
            v-if="checkCode('ACT_PRODUCT_RECYCLED') && get(scope.row, 'status.value') === 'readyToRecycle'"
            v-loading="loading"
            class="text-primary-100 underline"
            type="text"
            @click="manualRecycle(scope.row)"
          >
            手動回收
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="商品明細" prop="detail" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :editDisabled="!['coupon','classTicket'].includes(scope.row.type)"
            editBtn="檢視"
            hideDelete
            @edit="checkDetail(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <MemberShopOrderDetailClassTicketDialog
      v-if="modal.classTicketDetail"
      :productDetail="productDetail"
      @close="modal.classTicketDetail = false"
    />
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import { defineComponent, computed, ref, reactive } from 'vue'
import { imgSrc } from '@/utils/helper'
import { productStatusConfig } from '@/config/memberShop'
import { MemberShopOrderSendProduct, MemberShopOrderRecycleProduct } from '@/api/memberShop'
import { get } from 'lodash'
import store from '@/store'
import { useTransitionCode } from '../utils'
import { useRoute } from 'vue-router/composables'
import MemberShopOrderDetailClassTicketDialog from '@/components/Dialog/MemberShopOrderDetailClassTicketDialog.vue'
import classTicketDefaultImg from '@/assets/default/classTicket.svg'
import walletCoinDefaultImg from '@/assets/default/walletCoin.svg'

export default defineComponent({
  name: 'MemberShopOrderProductsTable',
  components: {
    Tag,
    MemberShopOrderDetailClassTicketDialog,
  },
  props: {
    products: { type: Array, default: () => [] },
    actions: { type: Array, default: () => [] },
    productDetail: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const route = useRoute()
    const modal = reactive({
      classTicketDetail: false,
      couponDetail: false,
    })
    const shopId = computed(() => store.getters.shop)
    const orderId = computed(() => route.params.id)
    const loading = ref(false)
    const displayImg = (row) => {
      if (row.Image) return imgSrc(300, row.Image)
      const type = row.type
      if (type === 'classTicket') return imgSrc(300, null, classTicketDefaultImg)
      if (type === 'wallet') return imgSrc(300, null, walletCoinDefaultImg)
    }
    const displayData = computed(() => {
      const products = props.products
      return products.map(p => {
        return {
          id: p.id,
          name: p.name,
          Image: get(p, 'MemberStoreProduct.Image'),
          isGift: p.isGift ? { label: '是', tagType: 'action' } : { label: '否', tagType: 'info' },
          price: p.price,
          type: p.type,
          quantity: p.quantity,
          total: p.totalPaidAmount,
          status: productStatusConfig[p.status],
        }
      })
    })
    const checkCode = computed(() => {
      const actions = props.actions
      const { actionCode } = useTransitionCode(actions)
      return (code) => actionCode(code)
    })
    const onHandle = async () => {}

    const manualSent = async (row) => {
      loading.value = true
      const [, err] = await MemberShopOrderSendProduct({
        shopId: shopId.value,
        orderId: orderId.value,
        id: row.id,
      })
      if (err) {
        loading.value = false
        window.$message.error(err)
        return
      }
      window.$message.success(`已手動發送商品： ${row.name}`)
      emit('refresh')
      loading.value = false
    }
    const manualRecycle = async (row) => {
      const [, err] = await MemberShopOrderRecycleProduct({
        shopId: shopId.value,
        orderId: orderId.value,
        id: row.id,
      })
      if (err) {
        loading.value = false
        window.$message.error(err)
        return
      }
      window.$message.success(`已手動回收商品： ${row.name}`)
      emit('refresh')
      loading.value = false
    }
    const checkDetail = (data) => {
      if (get(data, 'type') === 'classTicket') modal.classTicketDetail = true
    }
    return {
      get,
      loading,
      checkCode,
      productStatusConfig,
      displayData,
      onHandle,
      imgSrc,
      manualSent,
      manualRecycle,
      displayImg,
      modal,
      checkDetail,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
