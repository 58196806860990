<template>
  <GridInfoContainer title="訂單資訊" labelWidth="120" labelGap="12">
    <template #default="slotData">
      <InfoContainerItem :data="slotData" label="訂單編號" :value="get(order, 'code')" />
      <InfoContainerItem :data="slotData" label="訂單建立時間" :value="formatDate(get(order, 'createdAt'), 'YYYY-MM-DD HH:mm')" />
      <InfoContainerItem :data="slotData" label="訂單狀態">
        <Tag :type="orderStatus.tagType">{{ orderStatus.label }}</Tag>
      </InfoContainerItem>
      <InfoContainerItem :data="slotData" label="訂單總額" :value="`$ ${get(order, 'totalOriginalProductPrice')}`" />
    </template>
  </GridInfoContainer>
</template>

<script>
import { defineComponent, computed } from 'vue'
import GridInfoContainer from '@/components/Container/GridInfoContainer.vue'
import InfoContainerItem from '@/components/Container/InfoContainerItem.vue'
import Tag from '@/components/Tag/Tag.vue'
import { formatDate } from '@/utils/date'
import { orderStatusConfig } from '@/config/memberShop'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopOrderInfoSection',
  components: { GridInfoContainer, InfoContainerItem, Tag },
  props: {
    order: { type: Object, default: () => ({}) },
    actions: { type: Array, default: () => [] },
  },
  setup (props) {
    const orderStatus = computed(() => {
      const status = get(props.order, 'status')
      return orderStatusConfig[status] || {}
    })

    return { formatDate, orderStatus, get }
  },
})
</script>

<style scoped lang="postcss">

</style>
